export const questions = [
    {
        title: "When can I expect to see my credit score go up?",
        body: "Items will start getting removed in as little as 30 days, but the total process of raising your credit score typically takes longer than that.",
    },
    {
        title: "If an item is removed from my credit report, does it eliminate the debt?",
        body: "No, but if it is past the statute of limitations for the creditor to file a lawsuit, it will be much harder for them to try to collect it from you.",
    },
    {
        title: "What is included in the free evaluation?",
        body: "You will be contacted by one of our best companies’ expert credit advisors. They will discuss your credit report and how it relates to the FICO pie chart. They will also discuss a general strategy for raising your credit score.",
    },
];
