import { ReactElement } from "react";
import { questions } from "@lib/freecreditevaluation/faq";
import styles from "./style.module.scss";
import FaqImage from "../icons/faqImage";

export default function FAQ(): ReactElement {
    return (
        <div
            className={`flex ${styles["howThisWork"]} justify-between flex-col md:flex-row max-w-7xl mx-auto  px-4vw lg:px-4`}
        >
            <div className={`${styles["howThisWorkListWrapper"]}`}>
                <h2
                    className={`font-bold capitalize mb-12 text-3xl text-center md:text-left md:text-4xl ${styles["titleColor"]}`}
                >
                    frequently asked questions (FAQs)
                </h2>
                <div>
                    {questions.map((question) => (
                        <div className={`mb-12`} key={question.title}>
                            <div
                                className={`${styles["questionTitle"]} text-base md:text-xl font-bold  mb-2`}
                            >
                                {question.title}
                            </div>
                            <div
                                className={`${styles["stepText"]} text-base md:text-xl`}
                            >
                                {question.body}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={` ${styles["howThisWorkImage"]}`}>
                <FaqImage />
            </div>
        </div>
    );
}
